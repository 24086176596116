<template>
  <div v-if="pageContent" class="when-get-solar">
    <h1 class="h4 when-get-solar__title">
      {{ pageContent.whenGetSolarTitle }}
    </h1>
    <Combobox
      ref="whenGetSolarOptions-combobox"
      v-model="selectedWhenGetSolar"
      input-id="select-whenGetSolar"
      :options="whenGetSolarOptions"
      label="Please select"
      class="mb-7 when-get-solar__when-get-solar-select form-group__input-group"
    />
    <h1 class="h4 when-get-solar__title">
      {{ pageContent.customerPreferenceTitle }}
    </h1>
    <Combobox
      ref="CustomerPreference-combobox"
      v-model="selectedCustomerPreference"
      input-id="select-customerPreference"
      :options="customerPreferenceOptions"
      label="Please select"
      class="mb-7 when-get-solar__quote-preference-select form-group__input-group"
    />
    <h1 class="h4 when-get-solar__title">
      {{ pageContent.whoSuggestedTitle }}
    </h1>
    <Combobox
      ref="whoSuggested-combobox"
      v-model="selectedWhoSuggested"
      input-id="select-whoSuggested"
      :options="whoSuggestedOptions"
      label="Please select"
      class="mb-7 when-get-solar__who-suggested-select form-group__input-group"
    />
    <InputField
      v-if="selectedWhoSuggested?.value === 'Home Survey'"
      v-model.trim="surveyorId"
      class="mb-7 when-get-solar__who-suggested-select-surveyor-ID-input form-group__input-group"
      name="Surveyor ID Number"
      label="Surveyor ID Number"
      maxlength="4"
    />
    <InputField
      v-if="selectedWhoSuggested?.value === 'Voucher Code'"
      v-model.trim="voucherCode"
      class="mb-7 when-get-solar__who-suggested-select-surveyor-ID-input form-group__input-group"
      name="Voucher Code"
      label="Voucher Code"
    />
    <AppButton
      class="when-get-solar__next-button"
      full-width
      size="large"
      :disabled="
        !selectedWhenGetSolar ||
        !selectedCustomerPreference ||
        !selectedWhoSuggested ||
        (selectedWhoSuggested.value === 'Home Survey' && !surveyorId) ||
        (selectedWhoSuggested.value === 'Voucher Code' && !voucherCode)
      "
      @click="next"
    >
      <span>{{ pageContent.nextButton }}</span>
      <span
        class="icon when-get-solar__next-button--icon icon-arrow-right"
      ></span>
    </AppButton>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import Combobox from "@soenergy/frontend-library/src/components/Combobox.vue"
import cmsPreviewMixin from "@soenergy/frontend-library/src/mixins/cmsPreviewMixin"
import AppButton from "@soenergy/frontend-library/src/components/AppButton.vue"
import InputField from "@soenergy/frontend-library/src/components/InputField.vue"
import { mapMutations, mapGetters } from "vuex"

import story from "soenergy-cms-loader!?path=solar/when-get-solar"

export interface SolarIntent {
  title: string
  nextButton: string
  whenGetSolarTitle: string
  customerPreferenceTitle: string
  whoSuggestedTitle: string
  whenGetSolarOptions: Array<SolarIntentOption>
  customerPreferenceOptions: Array<SolarIntentOption>
  whoSuggestedOptions: Array<SolarIntentOption>
  options: SolarIntentOption[]
}

export interface SolarIntentOption {
  title: string
  value: string
}

export default defineComponent({
  components: {
    AppButton,
    Combobox,
    InputField,
  },
  mixins: [cmsPreviewMixin<SolarIntent>({ story })],
  emits: ["go-forward"],
  data() {
    return {
      selectedWhenGetSolar: null as SolarIntentOption | null,
      selectedWhoSuggested: null as SolarIntentOption | null,
      selectedCustomerPreference: null as SolarIntentOption | null,
      surveyorId: null as number | null,
      voucherCode: null as string | null,
    }
  },
  computed: {
    ...mapGetters([
      "getWhenGetSolar",
      "getWhoSuggested",
      "getCustomerPreference",
      "getSurveyorId",
      "getVoucherCode",
    ]),
    whenGetSolarOptions() {
      return this.pageContent.whenGetSolarOptions.map((option) => ({
        label: option.title,
        value: option.value,
      }))
    },
    customerPreferenceOptions() {
      return this.pageContent.customerPreferenceOptions.map((option) => ({
        label: option.title,
        value: option.value,
      }))
    },
    whoSuggestedOptions() {
      return this.pageContent.whoSuggestedOptions.map((option) => ({
        label: option.title,
        value: option.value,
      }))
    },
  },
  created() {
    this.selectedWhenGetSolar = this.getWhenGetSolar
    this.selectedWhoSuggested = this.getWhoSuggested
    this.selectedCustomerPreference = this.getCustomerPreference
    this.surveyorId = this.getSurveyorId
    this.voucherCode = this.getVoucherCode
  },
  methods: {
    ...mapMutations({
      setWhenGetSolar: "SET_WHEN_GET_SOLAR",
      setWhoSuggested: "SET_WHO_SUGGESTED",
      setCustomerPreference: "SET_CUSTOMER_PREFERENCE",
      setSurveyorId: "SET_SURVEYOR_ID",
      setVoucherCode: "SET_VOUCHER_CODE",
    }),
    next() {
      console.log(this.$store.state.quote)
      this.setWhenGetSolar(this.selectedWhenGetSolar)
      this.setWhoSuggested(this.selectedWhoSuggested)
      this.setCustomerPreference(this.selectedCustomerPreference)
      if (
        this.selectedWhoSuggested &&
        this.selectedWhoSuggested.value === "Home Survey"
      ) {
        this.setSurveyorId(this.surveyorId)
      } else {
        this.setSurveyorId(null)
      }

      if (
        this.selectedWhoSuggested &&
        this.selectedWhoSuggested.value === "Voucher Code"
      ) {
        this.setVoucherCode(this.voucherCode)
      } else {
        this.setVoucherCode(null)
      }
      this.$emit("go-forward")
    },
  },
})
</script>

<style lang="scss" scoped>
.when-get-solar {
  &__title {
    text-transform: none;
  }

  &__next-button {
    &--icon.icon {
      font-weight: $weight-medium;
      font-size: $space-5;
    }
  }

  &__when-get-solar-select,
  &__who-suggested-select,
  &__quote-preference-select {
    background-color: $white;
  }
}
</style>
